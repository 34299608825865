import React, { useEffect, useRef } from 'react'
import { useReduxDispatch } from '../redux'
import { load, byhash } from '../redux/results'
import { get } from '../redux/surveys'
import { Model } from 'survey-core'
import 'tabulator-tables/dist/css/tabulator.css'
import 'survey-analytics/survey.analytics.tabulator.css'
const SurveyAnalyticsTabulator = require('survey-analytics/survey.analytics.tabulator')

const ResultsByHashViewer = (params: { id: string, hash: string }): React.ReactElement => {
    const visContainerRef = useRef<HTMLDivElement>(null);
    const dispatch = useReduxDispatch()

    useEffect(() => {
        (async () => {
            const surveyAction = await dispatch(get(params.id))
            const survey = surveyAction.payload
            const resultsAction = await dispatch(byhash({ id: params.id, hash: params.hash }))
            const count = resultsAction.payload.count;
            console.log(resultsAction.payload);
            if (count > 0 && visContainerRef.current) {
                var model = new Model(survey.json);
                visContainerRef.current.innerHTML = '<div class="sjs-results-placeholder">Eredmények számossága: ' + count + '</div>';
            }
        })()
    }, [dispatch, params.id])    

    return (<>
            <div className='sjs-results-content' ref={visContainerRef}>
                <div className='sjs-results-placeholder'>
                    <span>Még nincs eredmény</span>
                </div>
            </div>
    </>)
}

export default ResultsByHashViewer