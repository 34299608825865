import Surveys from '../components/Surveys'

const Home = (props: any) => {
    const { loggedIn, email } = props
    return (
        <div className='sjs-client-app__content--surveys-list'>
            <h1>Szavazások</h1>
            <Surveys/>
        </div>
    )
}

export default Home;