import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { IUserDefinition } from '../models/user'
import { apiBaseAddress } from '../models/config'


const initialState: { users: Array<IUserDefinition>, editedUser: IUserDefinition, status: string, error: any } = {
    users: [],
    editedUser: { _id: '', username: '', email: '', password: '' },
    status: 'idle',
    error: null
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
          .addCase(load.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(load.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched users to the array
            state.users = action.payload;
          })
          .addCase(load.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
          .addCase(create.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add new user to the array
            state.users.push(action.payload)
          })
          .addCase(remove.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Remove user from the array
            console.log("Remove fulfilled", action, state.users);
            const user = state.users.filter(s => s._id == action.payload)[0];
            const index = state.users.indexOf(user);
            console.log(index);
            if(index >= 0) {
                state.users.splice(index, 1);
            }
          })
          .addCase(update.fulfilled, (state, action) => {
            state.status = 'succeeded'
            document.location = '/users'
            // const user = state.users.filter(s => s.username === action.payload.username)[0];
            // user.password = action.payload.password;
            // user.email = action.payload.email;
          })
      }
})

export const getToken = () => {
  let userString: string = "{}";
  if(localStorage.getItem("user") !== null) userString = String(localStorage.getItem("user"));
  let userObj = JSON.parse(userString);
  return userObj.token;
}

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const load = createAsyncThunk('users/load', async () => {
    const response = await axios.get(apiBaseAddress + '/users', { 
      headers: { Authorization: getAuthorizationHeader() }
    });
    return response.data
})

export const get = createAsyncThunk('users/get', async (userid: string) => {
    const response = await axios.get(apiBaseAddress + '/users/' + userid, { 
      headers: { Authorization: getAuthorizationHeader() }
    })
    return response.data
})

export const create = createAsyncThunk('users/create', async () => {
    const response = await axios.post(apiBaseAddress + '/users', {}, { 
      headers: { Authorization: getAuthorizationHeader() }
    });
    return response.data
})

export const remove = createAsyncThunk('users/delete', async (id: string) => {
    const response = await axios.delete(apiBaseAddress + '/users/' + id, { 
      headers: { Authorization: getAuthorizationHeader() }
    });
    return response.data
})

export const update = createAsyncThunk('users/update', async (data: {id: string, username: string, password: string, email: string}) => {
    const response = await axios.put(apiBaseAddress + '/users/' + data.id, data, { 
      headers: { Authorization: getAuthorizationHeader() }
    });
    return response.data
})

// export const { add, remove, update } = usersSlice.actions
export default usersSlice.reducer