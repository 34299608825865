import Surveys from '../components/Surveys'
import SurveyEdit from '../components/SurveyEdit';
import { useParams, Route, NavLink, Routes, useLocation, useNavigate } from "react-router-dom";

const SurveyManagement = (props: any) => {
    const { loggedIn, email, state } = props
    let { id } = useParams();
    switch(state) {
        case 'list':
            return (
                <div className='sjs-client-app__content--surveys-list'>
                    <h1>Szavazások</h1>
                    <Surveys />
                </div>
            );
        case 'edit':
            return (
                <div className='sjs-client-app__content--surveys-list'>
                    <h1>Szavazás módosítása</h1>
                    <SurveyEdit surveyid={String(id)} />
                </div>
            );
        default:
            return null;
    }
}

export default SurveyManagement;