import React, { useEffect, useState } from 'react'
import { get, update } from '../redux/users'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { Link, useParams } from 'react-router-dom'
import './UserList.css'
import { apiBaseAddress } from '../models/config'

interface IUserEditProps {
    userid: string,
}

const UserEdit = (props: IUserEditProps): React.ReactElement<IUserEditProps> => {
    let { userid } = props;
    let user = useReduxSelector(state => state.users.editedUser)
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useReduxDispatch()

    useEffect(() => {  
        if(!loaded) {
        let promise = dispatch(get(userid));
        promise.then((res) => {
            setUsername(res.payload.username);
            setEmail(res.payload.email);
            setLoaded(true);
        });
    }
    }, [loaded, username, email, dispatch])

    const updateUserData = async () => {
        const data = { id: userid, username: username, password: password, email: email }
        const result = await dispatch(update(data));
        console.log(result);
      }

    const onCancelButtonClick = () => {
        window.history.back();
    }

    const onUpdateButtonClick = () => {
        // Set initial error values to empty
        setEmailError('')
        setUsernameError('')
        setPasswordError('')
    
        if ('' === username) {
            setUsernameError('Kérem, adja meg a felhasználónevet!')
            return
        }

        if ('' === email) {
            setEmailError('Kérem, adja meg e-mail címét!')
            return
        }
    
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Kérem, érvényes e-mail címet adjon meg!')
            return
        }
    
        if (password.length > 0 && password.length < 7) {
            setPasswordError('A jelszó legalább 7 karakter hosszú legyen!')
            return
        }

        updateUserData().then((res) => { console.log("done"); document.location = '/users' });
    }

    return (<>
        <div className={'inputContainer'}>
            <label htmlFor="username">Felhasználónév:</label>
            <input type="text" name="username" id="username" className={'inputBox'} value={username} onChange={(ev) => setUsername(ev.target.value)} />
            <label className="errorLabel">{usernameError}</label>
        </div>
        <br />
        <div className={'inputContainer'}>
            <label htmlFor="email">E-mail cím:</label>
            <input type="text" name="email" id="email" className={'inputBox'} value={email} onChange={(ev) => setEmail(ev.target.value)} />
            <label className="errorLabel">{emailError}</label>
        </div>
        <br />
        <div className={'inputContainer'}>
            <label htmlFor="password">Jelszó:</label>
            <input type="password" name="password" id="password" className={'inputBox'} value={password} onChange={(ev) => setPassword(ev.target.value)} />
            <label className="errorLabel">{passwordError}</label>
        </div>
        <br />
        <div className={'inputContainer'}>
            <input className={'inputButton'} type="button" onClick={onUpdateButtonClick} value={'Módosítás'} />
        </div>
        <div className={'inputContainer'}>
            <input className={'inputButton'} type="button" onClick={onCancelButtonClick} value={'Mégsem'} />
        </div>
    </>)
}

export default UserEdit