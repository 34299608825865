import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useReduxDispatch } from '../redux'
import { post } from '../redux/results'
import { get, isCodeUsed } from '../redux/surveys'
import { Model, StylesManager, FunctionFactory } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.css'

StylesManager.applyTheme("defaultV2")

const Run = (): React.ReactElement => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()

    FunctionFactory.Instance.register("asyncFunction", asyncFunction, true);
    async function asyncFunction(data: any) {
        let code = data[0];
        let questionName = data[1];
        console.log("code length", code?.length, code);
        if(code?.length == 6) {
            let codeUsed = await dispatch(isCodeUsed({ id: id as string, code: code }))
            console.log("Returned codeUsed", codeUsed);
            model.getQuestionByName(questionName).value = codeUsed?.payload;
            return;
        }
        console.log("Returned codeUse(false)", false);
        model.getQuestionByName(questionName).value = false;
        return false;
    }


    //const surveys = useReduxSelector(state => state.surveys.surveys)
    const [survey, setSurvey] = useState({ name: "Betöltés", json: {} });

    useEffect(() => {
        const fetchData = async () => {
          const data = await dispatch(get(id as string));
          setSurvey({ name: data.payload.name, json: data.payload.json});
        }
        fetchData()
          .catch(console.error);
      }, [dispatch, id]);
    
    const model = new Model(survey.json)

    model
        .onComplete
        .add((sender: Model) => {
            dispatch(post({postId: id as string, surveyResult: sender.data, surveyResultText: JSON.stringify(sender.data)}))
        });    

    return (<>
        <Survey model={model}/>
    </>);
}

export default Run;