import { Route, NavLink, Routes, useLocation, useNavigate } from 'react-router-dom'
import Home from "../pages/Home"
import About from "../pages/About"
import Run from "../pages/Run"
import Edit from "../pages/Edit"
import Results from "../pages/Results"
import Login from "../components/Login"
import UserManagement from "../pages/UserManagement"
import SurveyManagement from "../pages/SurveyManagement"

const LogoutNavLink = (props: any) => {
    const { loggedIn } = props;
    if (loggedIn) {
        return (
            <>
                <NavLink className='sjs-nav-button' to="/logout"><span>Kijelentkezés</span></NavLink>
            </>
        );
    } else {
        return (<></>);
    }
}

export const NavBar = (props: any) => {
    const { loggedIn } = props;
    const location = useLocation();
    const navigate = useNavigate()
    const { pathname } = location;
    if (loggedIn && pathname.startsWith("/logout")) {
        localStorage.removeItem('user');
        props.setLoggedIn(false);
        navigate('/');
    }

    if (!loggedIn) {
        return (
            <>
                <Routes>
                    <Route path="*" element={
                        <span>
                            <NavLink className='sjs-nav-button' to="/"><span>Szavazások</span></NavLink>
                            <NavLink className='sjs-nav-button' to="/nevjegy"><span>Névjegy</span></NavLink>
                            <LogoutNavLink loggedIn={loggedIn}></LogoutNavLink>
                        </span>
                    }></Route>
                </Routes>

            </>
        )
    } else {
        return (
            <>
                <Routes>
                    <Route path="*" element={
                        <span>
                            <NavLink className='sjs-nav-button' to="/"><span>Szavazások</span></NavLink>
                            <NavLink className='sjs-nav-button' to="/users"><span>Felhasználók</span></NavLink>
                            <NavLink className='sjs-nav-button' to="/nevjegy"><span>Névjegy</span></NavLink>
                            <LogoutNavLink loggedIn={loggedIn}></LogoutNavLink>
                        </span>
                    }></Route>
                </Routes>

            </>
        )
    }
}

const NoMatch = () => (<><h1>404</h1><div>A keresett oldal nem található!</div></>)

const Content = (props: any) => {
    const { loggedIn, email, setLoggedIn } = props;

    if (!loggedIn) {
        return (
            <>
                <Routes>
                    <Route path="/" element={<Login setLoggedIn={setLoggedIn} />}></Route>
                    <Route path="/nevjegy" element={<About />}></Route>
                    <Route path="/szavazas/:id" element={<Run />}></Route>
                </Routes>
            </>
        )
    }
    return (
        <>
            <Routes>
                <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}></Route>
                <Route path="/nevjegy" element={<About />}></Route>
                <Route path="/szavazas/:id" element={<Run />}></Route>
                <Route path="/users" element={<UserManagement state="list" />}></Route>
                <Route path="/users/edit/:id" element={<UserManagement state="edit" />}></Route>
                <Route path="/surveys/edit/:id" element={<SurveyManagement state="edit" />}></Route>
                <Route path="/edit/:id" element={<Edit />}></Route>
                <Route path="/results/:id" element={<Results />}></Route>
                <Route element={<NoMatch />}></Route>
            </Routes>
        </>
    )
}

export default Content