import React, { useEffect, useState } from 'react'
import { get, updateBaseData } from '../redux/surveys'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { Link, useParams } from 'react-router-dom'
import './Surveys.css'
import { apiBaseAddress } from '../models/config'

interface ISurveyEditProps {
    surveyid: string,
}

const SurveyEdit = (props: ISurveyEditProps): React.ReactElement<ISurveyEditProps> => {
    let { surveyid } = props;
    let survey = useReduxSelector(state => state.surveys.editedSurvey)
    const [surveyname, setSurveyname] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [surveynameError, setSurveynameError] = useState('');
    const [zipcodeError, setZipcodeError] = useState('');
    const dispatch = useReduxDispatch()

    useEffect(() => {  
        if(!loaded) {
        let promise = dispatch(get(surveyid));
        promise.then((res) => {
            setSurveyname(res.payload.surveyname);
            setZipcode(res.payload.zipcode);
            setLoaded(true);
        });
    }
    }, [loaded, surveyname, zipcode, dispatch])

    const updateSurveyData = async () => {
        const data = { id: surveyid, surveyname: surveyname, zipcode: zipcode }
        const result = await dispatch(updateBaseData(data));
        console.log(result);
      }

    const onCancelButtonClick = () => {
        window.history.back();
    }

    const onUpdateButtonClick = () => {
        // Set initial error values to empty
        setSurveynameError('')
        setZipcodeError('')
    
        if ('' === surveyname) {
            setSurveynameError('Kérem, adja meg a szavazás/kérdőív címét!')
            return
        }
    
        updateSurveyData().then((res) => { document.location = '/surveys' });
    }

    return (<>
        <div className={'inputContainer'}>
            <label htmlFor="surveyname">Szavazás/kérdőív címe:</label>
            <input type="text" name="surveyname" id="username" className={'inputBox'} value={surveyname} onChange={(ev) => setSurveyname(ev.target.value)} />
            <label className="errorLabel">{surveynameError}</label>
        </div>
        <br />
        <div className={'inputContainer'}>
            <label htmlFor="zipcode">Irányítószám:</label>
            <input type="text" name="zipcode" id="zipcode" className={'inputBox'} value={zipcode} onChange={(ev) => setZipcode(ev.target.value)} />
            <label className="errorLabel">{zipcodeError}</label>
        </div>
        <br />
        <div className={'inputContainer'}>
            <input className={'inputButton'} type="button" onClick={onUpdateButtonClick} value={'Módosítás'} />
        </div>
        <div className={'inputContainer'}>
            <input className={'inputButton'} type="button" onClick={onCancelButtonClick} value={'Mégsem'} />
        </div>
    </>)
}

export default SurveyEdit