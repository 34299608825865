import React from 'react';
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import Content, { NavBar } from './routes'
import store from './redux';
import './App.css';
import logo from './logo.svg';
import { apiBaseAddress } from './models/config'

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    // Fetch the user email and token from local storage
    let userObj = localStorage.getItem('user');
    const user = JSON.parse(userObj != null ? userObj : "{}");
  
    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }
  
    // If the token exists, verify it with the auth server to see if it is valid
    fetch(apiBaseAddress + '/verify', {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + user.token,
      },
    })
      .then((r) => r.json())
      .then((r) => {
        setLoggedIn('success' === r.message)
        setEmail(user.email || '')
      })
  }, [])

  return (
    <Provider store={store}>
        <Router>
          <div className="sjs-client-app">
            <header className="sjs-client-app__header">
              <div className="header-title">Online szavazás</div>
              <NavBar loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            </header>
            <main className="sjs-client-app__content">
              <Content email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
            </main>
            <footer className="sjs-client-app__footer">
            </footer>
          </div>
        </Router>
    </Provider>
  );
}

export default App;
