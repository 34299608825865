import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiBaseAddress } from '../models/config'

const getToken = () => {
  let userString: string = "{}";
  if(localStorage.getItem("user") !== null) userString = String(localStorage.getItem("user"));
  let userObj = JSON.parse(userString);
  return userObj.token;
}

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const load = createAsyncThunk('results/load', async (id: string) => {
    const response = await axios.get(apiBaseAddress + '/results?postId=' + id, { 
      headers: { Authorization: getAuthorizationHeader() }
    })
    return response.data
})

export const post = createAsyncThunk('results/post', async (data: {postId: string, surveyResult: any, surveyResultText: string}) => {
  const response = await axios.post(apiBaseAddress + '/post', data);
  return response.data
})
