import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useReduxDispatch } from '../redux';
import { get } from '../redux/surveys'
import Viewer from '../components/Viewer'

const Results = () => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()
    const [title, setTitle] = useState('Eredmények');

    useEffect(() => {
        (async () => {
            const surveyAction = await dispatch(get(id as string))
            const survey = surveyAction.payload;
            setTitle(survey.name);
        })()
    }, [dispatch, id])    

    return (<>
        <h1>{title}</h1>
        <div className='sjs-results-container'>
            <Viewer id={id as string}/>
        </div>
    </>);
}

export default Results;