import React, { useEffect } from 'react'
import { create, load, remove } from '../redux/users'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { Link } from 'react-router-dom'
import './UserList.css'
import { IUserDefinition } from '../models/user'

const UserList = (): React.ReactElement => {
    const users = useReduxSelector(state => state.users.users)
    const dispatch = useReduxDispatch()

    const postStatus = useReduxSelector(state => state.users.status)

    useEffect(() => {
    console.log(postStatus);
      if (postStatus === 'idle') {
        dispatch(load())
      }
    }, [postStatus, dispatch])    

    function confirmAndDelete(user: IUserDefinition) {
        if(window.confirm("Valóban törölni szeretné a felhasználót (" + user.username + ")?")) {
            dispatch(remove(user._id))
        }
    }

    return (<>
        <table className='user-list'>
            <tbody>
            {users.map(user => 
                <tr key={user._id} className='user-list__row'>
                    <td><span>{user.username}</span></td>
                    <td><span>{user.email}</span></td>
                    <td width="212">
                        <Link className='user-button' to={'edit/' + user._id}><span>Módosítás</span></Link>
                        <span className='user-button user-remove-btn' onClick={() => confirmAndDelete(user)}>Törlés</span>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        <div className='user-list__footer'>
            <span className='user-button user-add-btn' title='increment' onClick={() => dispatch(create())}>Új felhasználó</span>                        
        </div>
    </>)
}

export default UserList