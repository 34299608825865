import UserList from '../components/UserList';
import UserEdit from '../components/UserEdit';
import { useParams, Route, NavLink, Routes, useLocation, useNavigate } from "react-router-dom";

const Home = (props: any) => {
    const { loggedIn, email, state } = props
    let { id } = useParams();
    switch(state) {
        case 'list':
            return (
                <div className='sjs-client-app__content--surveys-list'>
                    <h1>Felhasználók</h1>
                    <UserList />
                </div>
            );
        case 'edit':
            return (
                <div className='sjs-client-app__content--surveys-list'>
                    <h1>Felhasználó módosítása</h1>
                    <UserEdit userid={String(id)} />
                </div>
            );
        default:
            return null;
    }
}

export default Home;