import React, { useEffect } from 'react'
import { ISurveyDefinition } from '../models/survey'
import { create, load, remove } from '../redux/surveys'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { Link } from 'react-router-dom'
import './Surveys.css'
import { FaEdit, FaTrash, FaPlay, FaPoll, FaQuestion } from "react-icons/fa";

const Surveys = (): React.ReactElement => {
    const surveys = useReduxSelector(state => state.surveys.surveys)
    const dispatch = useReduxDispatch()

    const postStatus = useReduxSelector(state => state.surveys.status)

    useEffect(() => {
      if (postStatus === 'idle') {
        dispatch(load())
      }
    }, [postStatus, dispatch])    

    function confirmAndDelete(survey: ISurveyDefinition) {
        if(window.confirm("Valóban törölni szeretné a szavazást/kérdőívet (" + survey.name + ")?")) {
            dispatch(remove(survey.id))
        }
    }

    return (<>
        <table className='sjs-surveys-list'>
            <tbody>
            {surveys.map(survey => 
                <tr key={survey.id} className='sjs-surveys-list__row'>
                    <td><span>{survey.name}</span></td>
                    <td className="cell-icons">
                        <Link className='sjs-button' to={'surveys/edit/' + survey.id}><span><FaEdit title="Kérdések szerkesztés" /></span></Link>
                        <Link className='sjs-button' to={'szavazas/' + survey.id}><span><FaPlay title="Indítás" /></span></Link>
                        <Link className='sjs-button' to={'edit/' + survey.id}><span><FaQuestion title="Kérdések szerkesztés" /></span></Link>
                        <Link className='sjs-button' to={'results/' + survey.id}><span><FaPoll title="Eredmények" /></span></Link>
                        <span className='sjs-button sjs-remove-btn' onClick={() => confirmAndDelete(survey)}><FaTrash title="Törlés" /></span>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        <div className='sjs-surveys-list__footer'>
            <span className='sjs-button sjs-add-btn' title='increment' onClick={() => dispatch(create())}>Új szavazás</span>                        
        </div>
    </>)
}

export default Surveys
