import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiBaseAddress } from '../models/config'

const Login = (props: any) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()

  const onButtonClick = () => {
    // Set initial error values to empty
    setEmailError('')
    setPasswordError('')

    // Check if the user has entered both fields correctly
    if ('' === email) {
        setEmailError('Kérem, adja meg e-mail címét!')
        return
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setEmailError('Kérem, érvényes e-mail címet adjon meg!')
        return
    }

    if ('' === password) {
        setPasswordError('Kérem, adja meg jelszavát!')
        return
    }

    if (password.length < 7) {
        setPasswordError('A jelszó legalább 7 karakter hosszú legyen!')
        return
    }

    const checkAccountExists = (callback: any) => {
        fetch(apiBaseAddress + '/checkAccount', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
          .then((r) => r.json())
          .then((r) => {
            callback(r?.userExists)
          })
      }
      
      // Log in a user using email and password
      const logIn = () => {
        fetch(apiBaseAddress + '/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        })
          .then((r) => r.json())
          .then((r) => {
            if ('success' === r.message) {
              localStorage.setItem('user', JSON.stringify({ email, token: r.token, roles: r.roles, username: r.username }))
              props.setLoggedIn(true)
              setEmail(email)
              navigate('/')
            } else {
                setEmailError('A megadott e-mail cím és jelszó páros nem megfelelő!')
            }
          })
      }

    checkAccountExists((accountExists: any) => {
        // If yes, log in
        if (accountExists) {
            logIn()
        }
        // Else, ask user if they want to create a new account and if yes, then log in
        else {
            setEmailError('A megadott e-mail cím és jelszó páros nem megfelelő!')
        }
    });
  }

  return (
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>Bejelentkezés</div>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={email}
          placeholder="Kérem, írja be e-mail címét..."
          onChange={(ev) => setEmail(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          type="password"
          value={password}
          placeholder="Kérem, írja be a jelszót..."
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Bejelentkezés'} />
      </div>
    </div>
  )
}

export default Login